<script lang="ts">
  let klass = '';
  export { klass as class };
  export let size: string = '24px';
  export let width: string = size;
  export let height: string = size;
  export let viewWidth: string = '24';
  export let viewHeight: string = '24';
  export let spin = false;
  export let spinRate = '1s';
  export let spinTimingFunction = 'linear';
  export let disabled = false;
  export let path: string | null;
  export let label: string | undefined = undefined;
  export let hideLabel: boolean = false;
  export let style: string = '';
  export let color: string | null = null;
  export let tabindex: number | null = null;
  $: {
    width = width || size;
    height = height || size;
  }
</script>

<i
  {tabindex}
  aria-hidden="true"
  class="s-icon {klass}"
  class:spin
  aria-label={label}
  class:disabled
  aria-disabled={disabled}
  style="{style} {color
    ? `color: ${color}`
    : ''} --spin-rate: {spinRate}; --spin-timing: {spinTimingFunction}"
>
  <svg xmlns="http://www.w3.org/2000/svg" {width} {height} viewBox="0 0 {viewWidth} {viewHeight}">
    {#if path}
      <path d={path}>
        {#if label && !hideLabel}
          <title>{label}</title>
        {/if}
      </path>
    {/if}
  </svg>
  <slot />
</i>

<style lang="postcss">
  .s-icon {
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-flex;
    font-feature-settings: 'liga';
    justify-content: center;
    align-items: center;
    text-indent: 0;
    vertical-align: middle;
    cursor: inherit;
    user-select: none;
    direction: ltr;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;

    &.disabled {
    }

    &.spin {
      animation: infinite s-icon-spin var(--spin-timing, linear) var(--spin-rate, 1s);
    }

    > svg {
      fill: currentColor;
    }
  }

  @keyframes s-icon-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
</style>
